import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const MultiStepQuizForm = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false); // Loading state to handle the spinner
  const [formData, setFormData] = useState({
    country: '',
    usedBot: '',
    experience: '',
    mt5Familiarity: '',
    webinarInterest: '',
    investment: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    ip: '', // Hidden IP field
  });
  const [isNextDisabled, setIsNextDisabled] = useState(false); // State to track "Next" button status
  const [selectedOptions, setSelectedOptions] = useState({});
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [lastAnsweredStep, setLastAnsweredStep] = useState(-1);


  const moveStepRef = useRef(null);
  const updateFormDataRef = useRef(null);

  // Email validation function
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Phone validation function
  const validatePhone = (phone) => /^\d{10,15}$/.test(phone);


  // Define the updateFormDataRef function
  updateFormDataRef.current = (field, value, autoAdvance = true) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setSelectedOptions((prevSelected) => ({ ...prevSelected, [field]: value }));

    if (autoAdvance && step > lastAnsweredStep) {
      moveStepRef.current('next');
    }
  };

  const handlePasswordChange = useCallback((password) => {
    updateFormDataRef.current("password", password, false);
    setPasswordErrors(validatePassword(password));
  }, []);

  // Memoize questions to prevent re-creation on each render
  const questions = useMemo(() => [
    {
      id: 1,
      field: 'country', // field for country selection
      question: "What country are you from?",
      subHeadline: "*Our bot is only available in certain countries.",
      input: (
        <select
          className="selectField"
          value={formData.country}
          onChange={(e) => updateFormDataRef.current("country", e.target.value)}
        >
          <option value="">Select Country</option>
          <option value="US">United States</option>
          <option value="UK">United Kingdom</option>
          <option value="CA">Canada</option>
          <option value="AU">Australia</option>
          <option value="NZ">New Zealand</option>
          <option value="IE">Ireland</option>
          <option value="ZA">South Africa</option>
          <option disabled>──────────</option>
          <option value="AF">Afghanistan</option>
          <option value="AL">Albania</option>
          <option value="DZ">Algeria</option>
          <option value="AS">American Samoa</option>
          <option value="AD">Andorra</option>
          <option value="AO">Angola</option>
          <option value="AI">Anguilla</option>
          <option value="AQ">Antarctica</option>
          <option value="AG">Antigua and Barbuda</option>
          <option value="AR">Argentina</option>
          <option value="AM">Armenia</option>
          <option value="AW">Aruba</option>
          <option value="AT">Austria</option>
          <option value="AZ">Azerbaijan</option>
          <option value="BS">Bahamas</option>
          <option value="BH">Bahrain</option>
          <option value="BD">Bangladesh</option>
          <option value="BB">Barbados</option>
          <option value="BY">Belarus</option>
          <option value="BE">Belgium</option>
          <option value="BZ">Belize</option>
          <option value="BJ">Benin</option>
          <option value="BM">Bermuda</option>
          <option value="BT">Bhutan</option>
          <option value="BO">Bolivia</option>
          <option value="BA">Bosnia and Herzegovina</option>
          <option value="BW">Botswana</option>
          <option value="BR">Brazil</option>
          <option value="BN">Brunei</option>
          <option value="BG">Bulgaria</option>
          <option value="BF">Burkina Faso</option>
          <option value="BI">Burundi</option>
          <option value="KH">Cambodia</option>
          <option value="CM">Cameroon</option>
          <option value="CV">Cape Verde</option>
          <option value="KY">Cayman Islands</option>
          <option value="CF">Central African Republic</option>
          <option value="TD">Chad</option>
          <option value="CL">Chile</option>
          <option value="CN">China</option>
          <option value="CO">Colombia</option>
          <option value="KM">Comoros</option>
          <option value="CG">Congo - Brazzaville</option>
          <option value="CD">Congo - Kinshasa</option>
          <option value="CR">Costa Rica</option>
          <option value="CI">Côte d’Ivoire</option>
          <option value="HR">Croatia</option>
          <option value="CU">Cuba</option>
          <option value="CY">Cyprus</option>
          <option value="CZ">Czechia</option>
          <option value="DK">Denmark</option>
          <option value="DJ">Djibouti</option>
          <option value="DM">Dominica</option>
          <option value="DO">Dominican Republic</option>
          <option value="EC">Ecuador</option>
          <option value="EG">Egypt</option>
          <option value="SV">El Salvador</option>
          <option value="GQ">Equatorial Guinea</option>
          <option value="ER">Eritrea</option>
          <option value="EE">Estonia</option>
          <option value="SZ">Eswatini</option>
          <option value="ET">Ethiopia</option>
          <option value="FJ">Fiji</option>
          <option value="FI">Finland</option>
          <option value="FR">France</option>
          <option value="GA">Gabon</option>
          <option value="GM">Gambia</option>
          <option value="GE">Georgia</option>
          <option value="DE">Germany</option>
          <option value="GH">Ghana</option>
          <option value="GR">Greece</option>
          <option value="GD">Grenada</option>
          <option value="GU">Guam</option>
          <option value="GT">Guatemala</option>
          <option value="GN">Guinea</option>
          <option value="GW">Guinea-Bissau</option>
          <option value="GY">Guyana</option>
          <option value="HT">Haiti</option>
          <option value="HN">Honduras</option>
          <option value="HU">Hungary</option>
          <option value="IS">Iceland</option>
          <option value="IN">India</option>
          <option value="ID">Indonesia</option>
          <option value="IR">Iran</option>
          <option value="IQ">Iraq</option>
          <option value="IL">Israel</option>
          <option value="IT">Italy</option>
          <option value="JM">Jamaica</option>
          <option value="JP">Japan</option>
          <option value="JO">Jordan</option>
          <option value="KZ">Kazakhstan</option>
          <option value="KE">Kenya</option>
          <option value="KI">Kiribati</option>
          <option value="KW">Kuwait</option>
          <option value="KG">Kyrgyzstan</option>
          <option value="LA">Laos</option>
          <option value="LV">Latvia</option>
          <option value="LB">Lebanon</option>
          <option value="LS">Lesotho</option>
          <option value="LR">Liberia</option>
          <option value="LY">Libya</option>
          <option value="LI">Liechtenstein</option>
          <option value="LT">Lithuania</option>
          <option value="LU">Luxembourg</option>
          <option value="MG">Madagascar</option>
          <option value="MW">Malawi</option>
          <option value="MY">Malaysia</option>
          <option value="MV">Maldives</option>
          <option value="ML">Mali</option>
          <option value="MT">Malta</option>
          <option value="MH">Marshall Islands</option>
          <option value="MR">Mauritania</option>
          <option value="MU">Mauritius</option>
          <option value="MX">Mexico</option>
          <option value="FM">Micronesia</option>
          <option value="MD">Moldova</option>
          <option value="MC">Monaco</option>
          <option value="MN">Mongolia</option>
          <option value="ME">Montenegro</option>
          <option value="MA">Morocco</option>
          <option value="MZ">Mozambique</option>
          <option value="MM">Myanmar (Burma)</option>
          <option value="NA">Namibia</option>
          <option value="NR">Nauru</option>
          <option value="NP">Nepal</option>
          <option value="NL">Netherlands</option>
          <option value="NC">New Caledonia</option>
          <option value="NI">Nicaragua</option>
          <option value="NE">Niger</option>
          <option value="NG">Nigeria</option>
          <option value="MK">North Macedonia</option>
          <option value="NO">Norway</option>
          <option value="OM">Oman</option>
          <option value="PK">Pakistan</option>
          <option value="PW">Palau</option>
          <option value="PS">Palestine</option>
          <option value="PA">Panama</option>
          <option value="PG">Papua New Guinea</option>
          <option value="PY">Paraguay</option>
          <option value="PE">Peru</option>
          <option value="PH">Philippines</option>
          <option value="PL">Poland</option>
          <option value="PT">Portugal</option>
          <option value="QA">Qatar</option>
          <option value="RO">Romania</option>
          <option value="RU">Russia</option>
          <option value="RW">Rwanda</option>
          <option value="WS">Samoa</option>
          <option value="SM">San Marino</option>
          <option value="ST">São Tomé & Príncipe</option>
          <option value="SA">Saudi Arabia</option>
          <option value="SN">Senegal</option>
          <option value="RS">Serbia</option>
        </select>
      ),
      autoAdvance: true,
    },
    {
      id: 2,
      field: 'usedBot', // field for whether the user has used a bot
      question: "Have you ever used a trading bot before?",
      input: (
        <>
          <button
            className={`chooseButton ${selectedOptions.usedBot === "Yes" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("usedBot", "Yes")}
          >
            <span className="buttonLetter">Y</span>Yes
            {selectedOptions.usedBot === "Yes" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton ${selectedOptions.usedBot === "No" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("usedBot", "No")}
          >
            <span className="buttonLetter">N</span>No
            {selectedOptions.usedBot === "No" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
        </>
      ),
      autoAdvance: true,
    },
    {
      id: 3,
      field: 'experience', // field for trading experience
      question: "What is your level of trading experience?",
      input: (
        <>
          <button
            className={`chooseButton ${selectedOptions.experience === "Beginner" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("experience", "Beginner")}
          >
            <span className="buttonLetter">A</span>Beginner
            {selectedOptions.experience === "Beginner" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton ${selectedOptions.experience === "Intermediate" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("experience", "Intermediate")}
          >
            <span className="buttonLetter">B</span>Intermediate
            {selectedOptions.experience === "Intermediate" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton ${selectedOptions.experience === "Advanced" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("experience", "Advanced")}
          >
            <span className="buttonLetter">C</span>Advanced
            {selectedOptions.experience === "Advanced" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
        </>
      ),
      autoAdvance: true,
    },
    {
      id: 4,
      field: 'mt5Familiarity', // field for MT5 familiarity
      question: "How familiar are you with MT5?",
      input: (
        <>
          <button
            className={`chooseButton ${selectedOptions.mt5Familiarity === "Not at all" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("mt5Familiarity", "Not at all")}
          >
            <span className="buttonLetter">A</span>Not at all
            {selectedOptions.mt5Familiarity === "Not at all" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton ${selectedOptions.mt5Familiarity === "Average" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("mt5Familiarity", "Average")}
          >
            <span className="buttonLetter">B</span>Average
            {selectedOptions.mt5Familiarity === "Average" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton ${selectedOptions.mt5Familiarity === "Advanced" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("mt5Familiarity", "Advanced")}
          >
            <span className="buttonLetter">C</span>Advanced
            {selectedOptions.mt5Familiarity === "Advanced" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
        </>
      ),
      autoAdvance: true,
    },
    {
      id: 5,
      field: 'webinarInterest', // field for webinar interest
      question: "Would you be interested in attending a webinar or livestream?",
      input: (
        <>
          <button
            className={`chooseButton ${selectedOptions.webinarInterest === "Yes" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("webinarInterest", "Yes")}
          >
            <span className="buttonLetter">Y</span>Yes
            {selectedOptions.webinarInterest === "Yes" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton ${selectedOptions.webinarInterest === "No" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("webinarInterest", "No")}
          >
            <span className="buttonLetter">N</span>No
            {selectedOptions.webinarInterest === "No" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
        </>
      ),
      autoAdvance: true,
    },
    {
      id: 6,
      field: 'investment', // field for investment
      question: "If our bot can grow your account by 18.5%, how much would you invest?",
      input: (
        <>
          <button
            className={`chooseButton chooseButton__long ${selectedOptions.investment === "$10,000 or less" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("investment", "$10,000 or less")}
          >
            <span className="buttonLetter">A</span>$10,000 or less
            {selectedOptions.investment === "$10,000 or less" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton chooseButton__long ${selectedOptions.investment === "$10,000 to $50,000" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("investment", "$10,000 to $50,000")}
          >
            <span className="buttonLetter">B</span>$10,000 to $50,000
            {selectedOptions.investment === "$10,000 to $50,000" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton chooseButton__long ${selectedOptions.investment === "$50,000 to $150,000" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("investment", "$50,000 to $150,000")}
          >
            <span className="buttonLetter">C</span>$50,000 to $150,000
            {selectedOptions.investment === "$50,000 to $150,000" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
          <button
            className={`chooseButton chooseButton__long ${selectedOptions.investment === "$150,000 or more" ? 'selected' : ''}`}
            onClick={() => updateFormDataRef.current("investment", "$150,000 or more")}
          >
            <span className="buttonLetter">D</span>$150,000 or more
            {selectedOptions.investment === "$150,000 or more" && (
              <span className="checkmark">
                <svg height="13" width="16">
                  <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                </svg>
              </span>
            )}
          </button>
        </>
      ),
      autoAdvance: true,
    },
    {
      id: 7,
      field: 'firstName', // field for first and last name
      question: "What is your name?",
      input: (
        <>
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            className="textInput"
            onChange={(e) => updateFormDataRef.current("firstName", e.target.value, false)}
          />
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            className="textInput"
            onChange={(e) => updateFormDataRef.current("lastName", e.target.value, false)}
          />
        </>
      ),
      autoAdvance: false,
    },
    {
      id: 8,
      field: 'email', // field for email
      question: "What's your email?",
      input: (
        <input
          type="email"
          placeholder="Email"
          value={formData.email}
          className="emailField"
          onChange={(e) => updateFormDataRef.current("email", e.target.value, false)} // Disable auto-advance
        />
      ),
      autoAdvance: false,
    },
    {
      id: 9,
      field: 'phone',
      question: "What is your phone number?",
      input: (
        <PhoneInput
          country={'us'}
          value={formData.phone}
          onChange={(phone) => updateFormDataRef.current("phone", phone, false)} // Disable auto-advance
        />
      ),
      autoAdvance: false,
    },
    {
      id: 10,
      field: 'password', // field for password
      question: "Create a password for your account",
      input: (
        <>
          <div className="password-container">
            <input
              id="password"
              type="password"
              placeholder="Password"
              className="passwordField"
              value={formData.password}
              onChange={(e) => handlePasswordChange(e.target.value)}
            />
            <button
              type="button"
              className="toggle-password"
              onMouseDown={() => togglePasswordVisibility(true)}
              onMouseUp={() => togglePasswordVisibility(false)}
              onTouchStart={() => togglePasswordVisibility(true)}
              onTouchEnd={() => togglePasswordVisibility(false)}
              onMouseLeave={() => togglePasswordVisibility(false)}
            >
              👁️
            </button>
          </div>
          {passwordErrors.length > 0 && (
            <div className="password-errors">
              {passwordErrors.map((error, index) => (
                <p key={index} className="error-message">{error}</p>
              ))}
            </div>
          )}
        </>
      ),
      autoAdvance: false,
    }
  ], [formData, selectedOptions, handlePasswordChange, passwordErrors]);

  // Validation function for the current step
  const isStepValid = useCallback(() => {
    const currentQuestion = questions[step];
    switch (currentQuestion.id) {
      case 1: return formData.country !== '';
      case 2: return formData.usedBot !== '';
      case 3: return formData.experience !== '';
      case 4: return formData.mt5Familiarity !== '';
      case 5: return formData.webinarInterest !== '';
      case 6: return formData.investment !== '';
      case 7: return formData.firstName !== '' && formData.lastName !== '';
      case 8: return validateEmail(formData.email);
      case 9: return validatePhone(formData.phone);
      case 10: return formData.password !== '' && passwordErrors.length === 0;
      default: return true;
    }
  }, [formData, passwordErrors, questions, step]);



  moveStepRef.current = (direction) => {
    if (direction === 'next' && isStepValid() && !isNextDisabled) {
      setIsNextDisabled(true);
      setTimeout(() => {
        setStep((prevStep) => {
          const nextStep = Math.min(prevStep + 1, questions.length - 1);
          setLastAnsweredStep(Math.max(lastAnsweredStep, prevStep));
          return nextStep;
        });
        setIsNextDisabled(false);
      }, 300);
    } else if (direction === 'prev') {
      setStep((prevStep) => Math.max(prevStep - 1, 0));
    }
  };

  useEffect(() => {
    const currentQuestion = questions[step];

    // Auto-advance only if the current question allows it (autoAdvance is true) and the step is valid
    if (currentQuestion && currentQuestion.autoAdvance && isStepValid() && step > lastAnsweredStep) {
      moveStepRef.current('next');
    }
  }, [selectedOptions, isStepValid, questions, step, lastAnsweredStep]);


  const renderSubmitButton = () => (
    loading ? (
      <button className="submitButton" disabled>
        Submitting... <span className="spinner" />
      </button>
    ) : (
      <button onClick={handleSubmit} className="submitButton" disabled={!isStepValid()}>
        Submit
      </button>
    )
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const ip = await fetchIPAddress();
      const updatedFormData = { ...formData, ip };
      await submitForm(updatedFormData);
      window.location.href = '/submitted';
    } catch (error) {
      console.error('An issue occurred during form submission:', error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    const errors = [];

    // Check for spaces
    if (/\s/.test(password)) {
      errors.push("Password cannot contain spaces.");
    }

    // Check for a minimum length (e.g., 8 characters)
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }

    // Add more checks as needed (e.g., special characters, numbers, etc.)
    // For example: if (!/[!@#$%^&*]/.test(password)) { errors.push("Password must include a special character."); }

    return errors;
  };

  const togglePasswordVisibility = (isVisible) => {
    const passwordField = document.getElementById("password");
    if (passwordField) {
      passwordField.type = isVisible ? "text" : "password";
    }
  };

  const fetchIPAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Failed to fetch IP address:', error);
      return '';
    }
  };

  const submitForm = async (formData) => {
    await saveToGoogleSheet(formData);
    const selectedAPI = await fetchSelectedAPI();
    await fetch(selectedAPI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
  };

  const saveToGoogleSheet = async (formData) => {
    const googleScriptWebAppUrl = 'https://script.google.com/macros/s/AKfycbxNqaGt-xBMBR1KlScJIBSfBCTWkkq0dSmA2cwiQtA1y7obhNMuY7JAkCXuSociheyhwg/exec';
    await fetch(googleScriptWebAppUrl, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
  };

  const fetchSelectedAPI = async () => {
    const response = await fetch('/api/getSelectedAPI');
    const { selectedAPI } = await response.json();
    return selectedAPI;
  };


  return (
    <div id="multiStepQuizForm">
      <div className="quiz-container">
        <div className="progress-bar" style={{ width: `${((step + 1) / questions.length) * 100}%` }} />
        <div className="question__container">
          {step > 0 && (
            <button onClick={() => moveStepRef.current('prev')} className="back-button">
              ← Back
            </button>
          )}
          {questions.map((q, index) => (
            <div
              key={q.id}
              className={`quiz-step ${index === step ? 'active' : ''}`}
              style={{ display: index === step ? 'flex' : 'none' }}
            >
              <h2 className="questionText">{q.question}</h2>
              {q.subHeadline && <p className="subHeadline">{q.subHeadline}</p>}
              <div className="input-area">
                {React.cloneElement(q.input, {
                  onChange: (e) => updateFormDataRef.current(q.field, e.target ? e.target.value : e, q.autoAdvance)
                })}
              </div>
              {index === step && (
                index === questions.length - 1 ? (
                  renderSubmitButton()
                ) : (
                  <button
                    onClick={() => moveStepRef.current('next')}
                    className="nextButton"
                    disabled={!isStepValid() || isNextDisabled}
                  >
                    Next
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiStepQuizForm;
