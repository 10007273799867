import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';


const LoadingOverlay = () => (
    <div className="loading-overlay">
        <div className="loading-content">
            <div className="loading-circle"></div>
            <p>Building account...</p>
        </div>
    </div>
);

const SingleStepForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        fbclid: '',  // Hidden field for fbclid
        country: '', // Hidden country field
        ip: '', // Hidden IP field
    });

    const countryNameToIsoMap = {
        "Afghanistan": "AF",
        "Albania": "AL",
        "Algeria": "DZ",
        "Andorra": "AD",
        "Angola": "AO",
        "Antigua and Barbuda": "AG",
        "Argentina": "AR",
        "Armenia": "AM",
        "Australia": "AU",
        "Austria": "AT",
        "Azerbaijan": "AZ",
        "Bahamas": "BS",
        "Bahrain": "BH",
        "Bangladesh": "BD",
        "Barbados": "BB",
        "Belarus": "BY",
        "Belgium": "BE",
        "Belize": "BZ",
        "Benin": "BJ",
        "Bhutan": "BT",
        "Bolivia": "BO",
        "Bosnia and Herzegovina": "BA",
        "Botswana": "BW",
        "Brazil": "BR",
        "Brunei": "BN",
        "Bulgaria": "BG",
        "Burkina Faso": "BF",
        "Burundi": "BI",
        "Cabo Verde": "CV",
        "Cambodia": "KH",
        "Cameroon": "CM",
        "Canada": "CA",
        "Central African Republic": "CF",
        "Chad": "TD",
        "Chile": "CL",
        "China": "CN",
        "Colombia": "CO",
        "Comoros": "KM",
        "Congo, Democratic Republic of the": "CD",
        "Congo, Republic of the": "CG",
        "Costa Rica": "CR",
        "Côte d’Ivoire": "CI",
        "Croatia": "HR",
        "Cuba": "CU",
        "Cyprus": "CY",
        "Czech Republic": "CZ",
        "Denmark": "DK",
        "Djibouti": "DJ",
        "Dominica": "DM",
        "Dominican Republic": "DO",
        "Ecuador": "EC",
        "Egypt": "EG",
        "El Salvador": "SV",
        "Equatorial Guinea": "GQ",
        "Eritrea": "ER",
        "Estonia": "EE",
        "Eswatini": "SZ",
        "Ethiopia": "ET",
        "Fiji": "FJ",
        "Finland": "FI",
        "France": "FR",
        "Gabon": "GA",
        "Gambia": "GM",
        "Georgia": "GE",
        "Germany": "DE",
        "Ghana": "GH",
        "Greece": "GR",
        "Grenada": "GD",
        "Guatemala": "GT",
        "Guinea": "GN",
        "Guinea-Bissau": "GW",
        "Guyana": "GY",
        "Haiti": "HT",
        "Honduras": "HN",
        "Hungary": "HU",
        "Iceland": "IS",
        "India": "IN",
        "Indonesia": "ID",
        "Iran": "IR",
        "Iraq": "IQ",
        "Ireland": "IE",
        "Israel": "IL",
        "Italy": "IT",
        "Jamaica": "JM",
        "Japan": "JP",
        "Jordan": "JO",
        "Kazakhstan": "KZ",
        "Kenya": "KE",
        "Kiribati": "KI",
        "Korea, North": "KP",
        "Korea, South": "KR",
        "Kuwait": "KW",
        "Kyrgyzstan": "KG",
        "Laos": "LA",
        "Latvia": "LV",
        "Lebanon": "LB",
        "Lesotho": "LS",
        "Liberia": "LR",
        "Libya": "LY",
        "Liechtenstein": "LI",
        "Lithuania": "LT",
        "Luxembourg": "LU",
        "Madagascar": "MG",
        "Malawi": "MW",
        "Malaysia": "MY",
        "Maldives": "MV",
        "Mali": "ML",
        "Malta": "MT",
        "Marshall Islands": "MH",
        "Mauritania": "MR",
        "Mauritius": "MU",
        "Mexico": "MX",
        "Micronesia": "FM",
        "Moldova": "MD",
        "Monaco": "MC",
        "Mongolia": "MN",
        "Montenegro": "ME",
        "Morocco": "MA",
        "Mozambique": "MZ",
        "Myanmar": "MM",
        "Namibia": "NA",
        "Nauru": "NR",
        "Nepal": "NP",
        "Netherlands": "NL",
        "New Zealand": "NZ",
        "Nicaragua": "NI",
        "Niger": "NE",
        "Nigeria": "NG",
        "North Macedonia": "MK",
        "Norway": "NO",
        "Oman": "OM",
        "Pakistan": "PK",
        "Palau": "PW",
        "Panama": "PA",
        "Papua New Guinea": "PG",
        "Paraguay": "PY",
        "Peru": "PE",
        "Philippines": "PH",
        "Poland": "PL",
        "Portugal": "PT",
        "Qatar": "QA",
        "Romania": "RO",
        "Russia": "RU",
        "Rwanda": "RW",
        "Saint Kitts and Nevis": "KN",
        "Saint Lucia": "LC",
        "Saint Vincent and the Grenadines": "VC",
        "Samoa": "WS",
        "San Marino": "SM",
        "Sao Tome and Principe": "ST",
        "Saudi Arabia": "SA",
        "Senegal": "SN",
        "Serbia": "RS",
        "Seychelles": "SC",
        "Sierra Leone": "SL",
        "Singapore": "SG",
        "Slovakia": "SK",
        "Slovenia": "SI",
        "Solomon Islands": "SB",
        "Somalia": "SO",
        "South Africa": "ZA",
        "South Sudan": "SS",
        "Spain": "ES",
        "Sri Lanka": "LK",
        "Sudan": "SD",
        "Suriname": "SR",
        "Sweden": "SE",
        "Switzerland": "CH",
        "Syria": "SY",
        "Taiwan": "TW",
        "Tajikistan": "TJ",
        "Tanzania": "TZ",
        "Thailand": "TH",
        "Timor-Leste": "TL",
        "Togo": "TG",
        "Tonga": "TO",
        "Trinidad and Tobago": "TT",
        "Tunisia": "TN",
        "Turkey": "TR",
        "Turkmenistan": "TM",
        "Tuvalu": "TV",
        "Uganda": "UG",
        "Ukraine": "UA",
        "United Arab Emirates": "AE",
        "United Kingdom": "GB",
        "United States": "US",
        "Uruguay": "UY",
        "Uzbekistan": "UZ",
        "Vanuatu": "VU",
        "Vatican City": "VA",
        "Venezuela": "VE",
        "Vietnam": "VN",
        "Yemen": "YE",
        "Zambia": "ZM",
        "Zimbabwe": "ZW"
    };


    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(''); // State for form errors

    // const [passwordErrors, setPasswordErrors] = useState([]);

    // Email validation function
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    // Phone validation function
    const validatePhone = (phone) => /^\d{10,15}$/.test(phone);

    // const handlePasswordChange = (password) => {
    //     setFormData((prevData) => ({ ...prevData, password }));
    //     const errors = validatePassword(password);
    //     setPasswordErrors(errors);
    // };

    // const validatePassword = (password) => {
    //     const errors = [];
    //     if (/\s/.test(password)) {
    //         errors.push("Password cannot contain spaces.");
    //     }
    //     if (password.length < 8) {
    //         errors.push("Password must be at least 8 characters long.");
    //     }
    //     return errors;
    // };

    const fetchIPAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            setFormData((prevData) => ({ ...prevData, ip: data.ip }));
        } catch (error) {
            console.error('Failed to fetch IP address:', error);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');
        setFormData((prevData) => ({ ...prevData, fbclid: fbclid || '' }));
        fetchIPAddress();
    }, []);
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setFormError(''); // Clear previous error

    
        if (!validateEmail(formData.email)) {
            setFormError("Please enter a valid email.");
            setLoading(false);
            return;
        }
    
        if (!validatePhone(formData.phone)) {
            setFormError("Please enter a valid phone number.");
            setLoading(false);
            return;
        }
    
        try {
            const apiResponse = await sendToAPI(formData);
            const apiData = await apiResponse.json();
    
            const redirectUrl = apiData.data?.startsWith("https://qr.qlyxr.io")
                ? apiData.data
                : apiData.details?.redirect?.url?.startsWith("https://mskmd-login.com")
                    ? apiData.details.redirect.url
                    : null;
    
            const statusMessage = redirectUrl ? "Redirected Successfully" : apiData.data || "Unknown Error";
    
            // Save data to Google Sheet before redirecting
            await saveToGoogleSheet(formData, statusMessage);

            if (redirectUrl) {
                // Redirect if valid URL
                window.location.href = redirectUrl;
            } else {
                setFormError(statusMessage); // Display error if no URL found
            }
        } catch (error) {
            console.error("An issue occurred during form submission:", error);
            setFormError("There was an issue with your submission. Please try again.");
            await saveToGoogleSheet(formData, "Failed Submission");
        } finally {
            setLoading(false);
        }
    };
    
    

    const sendToAPI = async (formData) => {
        const apiUrl = 'https://leadsconnect.xyz/api/submitForm';
        let payload;
    
        // Fetch the selected API path from the backend
        const selectedApiResponse = await fetch('https://leadsconnect.xyz/api/selectedAPI');
        const { selectedAPI } = await selectedApiResponse.json();
    
        // Construct payload based on the selected API
        if (selectedAPI === '/api/api1') {
            // API 1 unique format
            payload = {
                firstname: formData.firstName,
                lastname: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                userip: formData.ip,  // Use `userip` for API 1
                password: "Abcdef3210",
                so: "ai-trader-fb"
            };
        } else if (selectedAPI === '/api/api2' || selectedAPI === '/api/api3') {
            // Common format for APIs 2 and 3
            payload = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                ip: formData.ip,  // Use `ip` for APIs 2 and 3
                password: "Abcdef3210",
                offerName: "ai-trader-fb"
            };
        } else if (selectedAPI === '/api/api4') {
            // API 4 format
            payload = {
                ip: formData.ip,               // `ip` field for API 4
                first_name: formData.firstName, // Use snake_case fields for API 4
                last_name: formData.lastName,
                email: formData.email,
                password: "Abcdef3210",
                phone: formData.phone,
                affiliate_id: "108fee6b03"
            };
        }
    
        // Send the payload to the server API endpoint
        return await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    };
    
    
    const saveToGoogleSheet = async (formData, status) => {
        const serverUrl = 'https://leadsconnect.xyz/api/saveToGoogleSheet';
    
        const dataToSend = {
            ...formData,
            status: status
        };
    
        try {
            const response = await fetch(serverUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataToSend),
            });
    
            if (!response.ok) {
                console.error("Failed to save data to Google Sheet:", response.statusText);
            } else {
                console.log("Data successfully saved to Google Sheet.");
            }
    
        } catch (error) {
            console.error("Error saving to Google Sheet:", error);
        }
    };
    
    

    const updateFormData = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const getCountryIsoCode = (countryName) => {
        return countryNameToIsoMap[countryName] || '';
    };

    const handlePhoneChange = (phone, countryData) => {
        const fullCountryName = countryData ? countryData.name : '';
        const countryCode = getCountryIsoCode(fullCountryName);

        setFormData((prevData) => ({
            ...prevData,
            phone: phone,
            country: countryCode, // Store the ISO 2-letter code
        }));
    };

    return (
        <div id="singleStepForm">
              {loading && <LoadingOverlay />}
            <div className="form__container">
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={(e) => updateFormData("firstName", e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={(e) => updateFormData("lastName", e.target.value)}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => updateFormData("email", e.target.value)}
                    />
                    <PhoneInput
                        country={'us'}
                        value={formData.phone}
                        onChange={(phone, countryData) => handlePhoneChange(phone, countryData)}
                    />
                    {/* <input
                        id="password"
                        type="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                    {passwordErrors.length > 0 && (
                        <div className="password-errors">
                            {passwordErrors.map((error, index) => (
                                <p key={index} className="error-message">{error}</p>
                            ))}
                        </div>
                    )} */}
                    <button type="submit" disabled={loading}>
                        {loading ? "Submitting..." : "Sign Up"}
                    </button>
                    {formError && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {formError}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SingleStepForm;
