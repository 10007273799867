import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SingleStepForm from './SingleStepForm'; // import your form component
import MultiStepQuizForm from './MultiStepQuizForm';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/quiz-form" element={<MultiStepQuizForm />} />
                <Route path="/single-step-form" element={<SingleStepForm />} />
            </Routes>
        </Router>
    );
}

export default App;